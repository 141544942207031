export default async function sendApiRequest(path, method = 'get', data = {}) {
    const token = localStorage.getItem('temp_token') ? 'Bearer ' + localStorage.getItem('temp_token') : null

    try {
        const isBody = data && method !== 'get' ? { body: JSON.stringify(data) } : {}

        const getData = await fetch(`${process.env.REACT_APP_SERVER_HOST}${path}`, {
            method,
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            ...isBody
        })

        const json = await getData.json()
        return await { result: true, status: getData.status, ...json }
    } catch (error) {
        return { result: false, message: error.message }
    }
}