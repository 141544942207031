import Options from "./Options/Options";

const Menu = ({isOpenMenu, gameOptions, setGameOptions}) => {


    return (
        <div id="menu" className={isOpenMenu ? 'open' : ''}>
            <Options 
                title='Быстрая игра' 
                isActive={gameOptions.fast_game} 
                changeActive={() => setGameOptions({...gameOptions, fast_game: !gameOptions.fast_game})}
            >
                Играть быстрее уменьшив общее время спина
            </Options>

            <Options 
                title='Экономия батареи' 
                isActive={gameOptions.safe_charge} 
                changeActive={() => setGameOptions({...gameOptions, safe_charge: !gameOptions.safe_charge})}
            >
                Продлите время работы аккумулятора, уменьшив скорость анимации
            </Options>

            <Options 
                title='Фоновая музыка' 
                isActive={gameOptions.music} 
                changeActive={() => setGameOptions({...gameOptions, music: !gameOptions.music})}
            >
                Включите или выключите игровую музыку
            </Options>

            <Options 
                title='Звуковые эффекты' 
                isActive={gameOptions.sound_effects} 
                changeActive={() => setGameOptions({...gameOptions, sound_effects: !gameOptions.sound_effects})}
            >
                Включите или выключите игровые звуки
            </Options>

            <Options 
                title='Заставка' 
                isActive={gameOptions.screensaver} 
                changeActive={() => setGameOptions({...gameOptions, screensaver: !gameOptions.screensaver})}
            >
                Показывать заставку перед началом игры
            </Options>
        </div>
    );
}
 
export default Menu;