import './QuestionWindow.scss'
import multers1 from '../../images/elements/rules_multiplers.png'
import multers2 from '../../images/elements/rules_multiplers2.png'
import lines from '../../images/elements/lines.png'

const QuestionWindow = ({isOpenWindow, toggleWindow}) => {
    return (
        <div id="question-window" className={isOpenWindow ? 'open' : ''}>
            <div className="close" onClick={toggleWindow}>×</div>
            <div className="title">
                <h2>Amur Speens</h2>
            </div>
            <div className="content">
                <h3>ОСОБЫЕ СИМВОЛЫ - БЕСПЛАТНЫЕ СПИНЫ</h3>
                <p>
                    3 и более символов Free Spins, выпавшие в любом месте на барабанах, запускают игру беспланые спиныю<br />
                    4 и более символа Free Spins - 5 бесплатных спинов<br />
                    Ставка на линию и выбранные линии выигрыша остаются такими же как в игре, вызвавшей раунд бесплатных спинов.<br /> 
                    Появляются только во время обычной игры.
                </p>
                <h3>ОСОБЫЕ СИМВОЛЫ - WILD</h3>
                <p>
                    Символы Wild засчитываются за любой символ, кроме бесплатных спинов. <br />
                    Выпадение 5 символов Wild на любой линии выигрыша приносит выплату х3000 к ставке на линию
                </p>
                <h3>СРЕДНИЕ СИМВОЛЫ</h3>
                <img src={multers2} alt="Multiplers" />
                <h3>МЛАДШИЕ СИМВОЛЫ</h3>
                <img src={multers1} alt="Multiplers" />
                <h3>ЛИНИИ</h3>
                <p>
                    Выплата производится за совпадение слева направо символы, начиная с крайнего левого барабана.<br />
                    Только самый крупный выигрыш по каждой линии подледит выплате.<br />
                    Выигрыш умножается на сумму ставки на линию. <br />
                    В случае сбоев все выигрыши аннулируются. <br />
                </p>
                <h3>ПРАВИЛА</h3>
                <ul>
                    <li>Нажмите на кнопку «Спин» для запуска барабанов. Последовательности совпавших символов на игровых линиях приносят выигрыш.</li>
                    <li>Нажмите «+» или «-» на кнопках правой панели, чтобы выбрать сумму ставки.</li>
                    <li>Во время Бесплатных Спинов кнопки «+» и «-» не активны.</li>
                    <li>Во время спина кнопки «Помощь» и «Настройки» не активны.</li>
                    <li>Все выигрыши за один раунд игры отображаются в поле «Выигрыш».</li>
                    <li>Выплата производится за совпавшие слева направо символы, начиная с крайнего левого барабана.</li>
                    <li>Только самый крупный выигрыш по каждой линии подлежит выплате.</li>
                    <li>Выигрыш умножается на сумму ставки на линию. В случае сбоев все выигрыши аннулируются.</li>
                    <li>Все выигрышные комбинации и выплаты доступны для просмотра в ТАБЛИЦЕ ВЫИГРЫШЕЙ.</li>
                </ul>
                <h3>ЛИНИИ</h3>
                <img src={lines} alt="Lines" />
                <ul>
                    <li>Выигрыш в нескольких линиях выплат: каждая комбинация, засчитывающаяся в качестве выигрыша на линию выплат в одном раунде, суммируется с общим выигрышем в этом раунде.</li>
                </ul>
                <h3>АВТО-ИГРА</h3>
                <ul>
                    <li>Кнопка Авто-игра позволяет делать одну и ту же ставку раз за разом без участия игрока.</li>
                    <li>После нажатия кнопки Автоигра функция активируется.</li>
                    <li>Автоигра может быть остановлена повторным нажатием на кнопку Автоигры</li>
                </ul>
                <h3>БЫСТАЯ ИГРА</h3>
                <ul>
                    <li>Быстая игра применяется для того, чтобы сыграть раунд игры наиболее быстро.</li>
                    <li>Быстрая игра запускается переключением ползунка, расположенного в Меню Настроек.</li>
                </ul>
                <h3>МАГАЗИН СПИНОВ</h3>
                <ul>
                    <li>Магазин спинов позволяет вам приобрести и немедленно сыграть раунд Бесплатных Спинов.</li>
                    <li>При нажатии на кнопку "МАГАЗИН СПИНОВ", перед вами появится окно, в котором вы можете изменить размер ставки, а также число бесплатных спинов, которое вам предстоит сыграть во время раунда бесплатных спинов.</li>
                    <li>Цена покупки устанавливается в зависимости от ставки и числа бесплатных спинов.</li>
                    <li>Нажмите на кнопку Купить чтобы начать раунд Бесплатных Спинов.</li>
                </ul>
            </div>
        </div>
    );
}
 
export default QuestionWindow;