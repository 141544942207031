import styles from './Options.module.css'

import brailleIcon from '../../images/icons/braille.png'

const Options = ({isActive, changeActive, title, children}) => {
    return (
        <div className={styles.options}>
            <div className={styles.text_block}>
                <h3 className={styles.title}>{title}</h3>
                <p className={styles.subtitle}>{children}</p>
            </div>
            <div className={[isActive ? styles.changer_active : styles.changer]} onClick={changeActive}>
                <div className={[isActive ? styles.switcher_right : styles.switcher_left]}>
                    <img className={styles.braille} src={brailleIcon} alt="braille icon" />
                </div>
            </div>
        </div>
    );
}
 
export default Options;