import sendApiRequest from "../tools/sendApiRequest"

class GameData {
    gamer = async () => {
        const data = await sendApiRequest('api/game/gamer', 'get')
        if (!data.result) return console.error(data.message)

        return data
    }

    matrix = async (bet) => {
        const data = await sendApiRequest('api/game/matrix', 'post', { ...bet })
        if (!data.result) return console.error(data.message)

        return data.game
    }

    fakeMatrix = async () => {
        const data = await sendApiRequest('api/game/placeholder', 'get')
        if (!data.result) return console.error(data.message)

        return data.placeholder
    }

    freespins = async (buy) => {
        const data = await sendApiRequest('api/game/freespins', 'post', buy)
        if (!data.result) return console.error(data.message)

        return data
    }
}

const gameData = new GameData()
export default gameData