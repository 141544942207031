import { useEffect, useRef, useState } from 'react';
import rubleIcon from './images/icons/ruble.png'
import Menu from './components/Menu';
import QuestionWindow from './components/QuestionWindow/QuestionWindow';
import GameData from './api/GameData';

import hoverAudio from './sound/hover.mp3'
import clickAudio from './sound/click.mp3'
import spinAudio from './sound/spin.mp3'
import themeMusic from './sound/theme.mp3'
import colStopAudio from './sound/col-stoped.mp3'

import moneyWin from './images/lines/money.png'
import freespinsWin from './images/lines/freespins.png'
import girlOnCloud from './images/elements/girl.png'
import startButton from './images/elements/start_button.png'

import './App.scss'

const App = () => {
    const [ matrix, setMatrix ] = useState([])
    const [ gamer, setGamer ] = useState({ balance: 0, currencyBalance: 0, freespins: { active: false, count: 0 } })
    const [ gameOptions, setGameOptions ] = useState()
    const [ currentGame, setCurrentGame ] = useState({ bet: 10, isSpining: false, freespins_bet: 0 })
    const [ buyFspeens, setBuyFspeens ] = useState({ fspeens: 10, bet: 10, isOpen: false })
    const [ winCount, setWinCount ] = useState(0)
    const [ doSpin, setDoSpin ] = useState(false)

    const [ winLines, setWinLines ] = useState([])
    const [ isFakeBalance, setIsFakeBalance ] = useState(true)
    const [ isLoadWindow, setIsLoadWindow ] = useState(true)
    const [ isLoadWindowVisible, setIsLoadWindowVisible ] = useState(true)
    const [ isMenuOpen, setIsMenuOpen ] = useState(false)
    const [ isHelpOpen, setIsHelpOpen ] = useState(false)
    const [ isWinWindowOpen, setIsWinWindow ] = useState(false)

    const themeMusicRef = useRef(null)
    const hoverAudioRef = useRef(null)
    const clickAudioRef = useRef(null)
    const spinAudioRef = useRef(null)
    const colStopAudioRef = useRef(null)

    useEffect(() => { // Загрузка настроек из localStorage
        const go = localStorage.getItem('gameOptions') || null

        if (go) {
            const options = JSON.parse(go)
            setGameOptions(options)
        } else {
            const options = { fast_game: false, safe_charge: false, music: true, sound_effects: true, screensaver: true, autoSpin: false }
            setGameOptions(options)
            localStorage.setItem('gameOptions', JSON.stringify(options))
        }
    }, [])

    useEffect(() => { // Перезапись настроек в localStorage
        localStorage.setItem('gameOptions', JSON.stringify(gameOptions))
    }, [gameOptions])

    useEffect(() => { // Обработчик вращения через нажатия Enter или Space
        const keyPress = (e) => {
            if (e.code === 'Space' || e.code === 'Enter') {
                setDoSpin(true)
            }
        }

        document.addEventListener('keydown', keyPress)

        return () => {
            document.removeEventListener('keydown', keyPress)
        }
    }, [])

    useEffect(() => { // Подгрузка данных в игру
        const getGamerData = async () => {
            const gamerData = await GameData.gamer()
            const placeholderMatrix = await GameData.fakeMatrix()
            const splitPMatrix = splitArrayIntoColumns(placeholderMatrix, 5)

            localStorage.setItem('temp_token', gamerData.token)
            setGamer(gamerData.gamer)
            setMatrix(splitPMatrix)
        }

        getGamerData()
    }, [])

    useEffect(() => { // Проверка на автоспин
        if (gameOptions?.autoSpin && winLines.length === 0) setDoSpin(true)
        // eslint-disable-next-line
    }, [ currentGame.isSpining ])

    useEffect(() => { // Запуск вращения
        if (doSpin && !currentGame.isSpining) {
            if (gamer.balance - currentGame.bet > 0)
                updateMatrix()
        }
        // eslint-disable-next-line
    }, [doSpin, currentGame.isSpining])

    useEffect(() => { // Плавный счетчик для выигрыша
        const startCounter = async () => {
            const fnlCount = winLines.reduce((acc, line) => acc + line.count, 0)
            setWinCount(1)

            let iter = 1

            while (iter < fnlCount) {
                if (fnlCount <= 10) {
                    iter += 1
                    await timeout(150)
                } else if (fnlCount <= 1000) {
                    iter += 1
                    await timeout(10)
                } else {
                    if (fnlCount - (winCount + 11) < 11) 
                        iter = fnlCount
                    else 
                        iter += 11
                    await timeout(10)
                }
                setWinCount(iter)
            }

            setWinCount(fnlCount)
        }

        if (!currentGame.isSpining && winLines.length > 0) {
            startCounter()
        }
        // eslint-disable-next-line
    }, [currentGame.isSpining, winLines.length, winLines])

    useEffect(() => { // Музыкальное сопровождение
        if (themeMusicRef.current) {
            if (gameOptions?.music && !isLoadWindow) {
                themeMusicRef.current.play()
            }
            else {
                themeMusicRef.current.pause()
            }
        }
    }, [gameOptions?.music, isLoadWindow])


    const slowCloseLoadWindow = () => {
        setIsLoadWindowVisible(false);
        setTimeout(() => setIsLoadWindow(false), 300)
    }

    const updateMatrix = async () => {
        if (gamer.balance < currentGame.bet) return null;
        if (currentGame.isSpining) return null;

        if (gamer.freespins.count === 0) {
            setGamer(prevGamer => ({ ...prevGamer, balance: prevGamer.balance - currentGame.bet }));
        } else {
            setGamer(prevGamer => ({
                ...prevGamer,
                freespins: { ...prevGamer.freespins, count: prevGamer.freespins.count - 1 }
            }));
        }
        setWinLines([]);

        if (gameOptions.sound_effects) {
            playSpinSound();
        }

        if (currentGame.isSpining) return false;

        const game = await GameData.matrix(currentGame);
        console.log(game);

        const mergedMatrix = mergeColumnsIntoArray(matrix)
        const oldMatrix = mergedMatrix.splice(0, 19)

        const splitedMatrix = splitArrayIntoColumns([...game.matrix, ...oldMatrix], game.matrixOpt.cols);

        setMatrix(splitedMatrix);
        setCurrentGame(prevGame => ({ ...prevGame, isSpining: true }));

        const spinDuration = gameOptions.fast_game ? 3000 : 7000;

        if (gameOptions.sound_effects) {
            setTimeout(() => {
                colStopAudioRef.current.currentTime = 0;
                colStopAudioRef.current.play();
            }, spinDuration - (gameOptions.fast_game ? 200 : 300));

            setTimeout(() => {
                colStopAudioRef.current.currentTime = 0;
                colStopAudioRef.current.play();
            }, spinDuration - (gameOptions.fast_game ? 400 : 600));

            setTimeout(() => {
                colStopAudioRef.current.currentTime = 0;
                colStopAudioRef.current.play();
            }, spinDuration - (gameOptions.fast_game ? 600 : 900));

            setTimeout(() => {
                colStopAudioRef.current.currentTime = 0;
                colStopAudioRef.current.play();
            }, spinDuration - (gameOptions.fast_game ? 800 : 1200));

            setTimeout(() => {
                colStopAudioRef.current.currentTime = 0;
                colStopAudioRef.current.play();
            }, spinDuration - (gameOptions.fast_game ? 1000 : 1500));
        }

        await timeout(gameOptions.fast_game ? 3000 : 7000)
        
        setCurrentGame(prevGame => ({ ...prevGame, isSpining: false }));
        setDoSpin(false)

        if (game.result.isWin) {
            setWinLines(game.result.results);
            setGameOptions(prevOptions => ({ ...prevOptions, autoSpin: false }));
            setIsWinWindow(true);
        }

        localStorage.setItem('temp_token', game.result.token);

        setGamer(prevGamer => ({
            ...prevGamer,
            balance: game.result.balance,
            freespins: game.result.freespins
        }));

        console.log(winLines);
    };

    const splitArrayIntoColumns = (arr, numCols) => {
        const columns = Array.from({ length: numCols }, () => []);

        arr.forEach((value, index) => {
            const columnIndex = index % numCols
            columns[columnIndex].push(value)
        })
        
        return columns
    }

    const mergeColumnsIntoArray = (columns) => {
        const maxLength = Math.max(...columns.map(col => col.length));
        const result = [];
    
        for (let i = 0; i < maxLength; i++) {
            for (let j = 0; j < columns.length; j++) {
                if (columns[j][i] !== undefined) {
                    result.push(columns[j][i]);
                }
            }
        }
    
        return result;
    };

    const playHoverSound = () => {
        if (hoverAudioRef.current && gameOptions.sound_effects) {
            hoverAudioRef.current.currentTime = 0
            hoverAudioRef.current.play()
        }
    }

    const playClickSound = () => {
        if (clickAudioRef.current && gameOptions.sound_effects) {
            clickAudioRef.current.currentTime = 0
            clickAudioRef.current.play()
        }
    }

    const playSpinSound = () => {
        // 5,6
        if (spinAudioRef.current && gameOptions.sound_effects) {
            spinAudioRef.current.currentTime = 0
            spinAudioRef.current.volume=0.5

            if (gameOptions.fast_game) spinAudioRef.current.playbackRate = 5.6
            else spinAudioRef.current.playbackRate = 1.85

            spinAudioRef.current.play()
        }
    }

    const toggleAutoSpin = () => {
        setGameOptions(prevOptions => ({
            ...prevOptions,
            autoSpin: !prevOptions.autoSpin
        }));
    };

    const handleBuyFspeensCount = (count) => {
        if (count > 0) {
            if (gamer.balance - (buyFspeens.bet * 10) * (buyFspeens.fspeens + count) >= 0) {
                setBuyFspeens({ ...buyFspeens, fspeens: buyFspeens.fspeens + count })
            }
        } else {
            if ((buyFspeens.bet * 10) * (buyFspeens.fspeens + count ) > 0) {
                setBuyFspeens({ ...buyFspeens, fspeens: buyFspeens.fspeens + count })
            }
        }
    }

    const handleBuyFspeensBet = (count) => {
        if (count > 0) {
            if (gamer.balance - (buyFspeens.bet * 10) * (buyFspeens.fspeens + count) >= 0) {
                setBuyFspeens({ ...buyFspeens, bet: buyFspeens.bet + count })
            }
        } else {
            if ((buyFspeens.bet * 10) * (buyFspeens.fspeens + count ) > 0) {
                setBuyFspeens({ ...buyFspeens, bet: buyFspeens.bet + count })
            }
        }
    }

    const handleBuyFspeens = async () => {
        if (gamer.freespins.count !== 0) return null;

        const buy = {
            bet: buyFspeens.bet,
            count: buyFspeens.fspeens,
            price: buyFspeens.bet * buyFspeens.fspeens * 10
        }

        const data = await GameData.freespins(buy)
        setGamer(data.gamer)
        localStorage.setItem('temp_token', data.token)
    }

    const timeout = (delay) => {
        return new Promise( res => setTimeout(res, delay) );
    }

    return (
        !isLoadWindow ?
        <div className={`wrapper ${!isLoadWindow ? 'visible' : 'hidden'}`}>
            <audio src={clickAudio} ref={clickAudioRef}></audio>
            <audio src={hoverAudio} ref={hoverAudioRef}></audio>
            <audio src={spinAudio} ref={spinAudioRef}></audio>
            <audio src={themeMusic} ref={themeMusicRef} loop></audio>
            <audio src={colStopAudio} ref={colStopAudioRef}></audio>

            <div id="buy-free-spins" className={buyFspeens.isOpen ? 'open' : ''} onClick={() => setBuyFspeens({ ...buyFspeens, isOpen: false })}>
                <div className="content" onClick={e => e.stopPropagation()}>
                    <div className="container">
                        <h5>Количество фриспинов</h5>
                        <div className="input">
                            <b onClick={() => handleBuyFspeensCount(-5)}>-</b>
                            <p className="display">{buyFspeens.fspeens}</p>
                            <b onClick={() => handleBuyFspeensCount(5)}>+</b>
                        </div>
                    </div>
                    <div className="container">
                        <h5>Сумма ставки</h5>
                        <div className="input">
                            <b onClick={() => handleBuyFspeensBet(-5)}>-</b>
                            <p className="display">{buyFspeens.bet}</p>
                            <b onClick={() => handleBuyFspeensBet(5)}>+</b>
                        </div>
                    </div>
                    <div className="container">
                        <h5>Стоимость покупки</h5>
                        <div className="input">
                            <p className="display">{(buyFspeens.bet * 10) * buyFspeens.fspeens}</p>
                        </div>
                    </div>
                    <div className="buttons">
                        <button className='close' onClick={() => setBuyFspeens({ ...buyFspeens, isOpen: false })}>отменить</button>
                        <button className='buy' onClick={handleBuyFspeens}>купить</button>
                    </div>
                </div>
            </div>

            <Menu isOpenMenu={isMenuOpen} gameOptions={gameOptions} setGameOptions={setGameOptions} />
            <QuestionWindow isOpenWindow={isHelpOpen} toggleWindow={() => setIsHelpOpen(!isHelpOpen)} />

            <section id="lines" className={`${isWinWindowOpen ? 'open' : ''}`} onClick={() => setIsWinWindow(false)}>
                {winLines.length > 0 ? 
                    <div className="winner-window">
                        <img src={winLines[0].type === 'money' ? moneyWin : freespinsWin} alt="Winner" />
                        <p>{winCount}</p>
                        <img className='button' src={require('./images/elements/continue.png')} alt="Button" />
                    </div>
                 : null}
            </section>

            <div id="slot-machine">
                <div className={`machine-window ${currentGame.isSpining ? 'spin' : ''} ${winLines.join(' ')}`}>
                    <div className="game-title">
                        {gamer.freespins.count > 0 
                            ? <p>Freespins <span>{gamer.freespins.count}</span></p>
                            : <h4>Amur Speens</h4>
                        }
                    </div>
                    <div className="cols">
                        {matrix.length > 0 ? matrix.map((col, сindex) =>
                            <div className="col" key={сindex} style={{'--spin-time': `${(gameOptions.fast_game ? 3000 : 7000) / 1000}s`, '--index': сindex}}>
                                {col.map((number, sindex) => {
                                    const currentCol = сindex + (sindex * 5)
                                    const winNumbers = []
                                    
                                    winLines.map(line => winNumbers.push(...line.combination))

                                    const isThisSlot = winNumbers.includes(currentCol)

                                    return (
                                        <div className={`slot ${isThisSlot ? 'win' : ''}`} key={sindex}>
                                            <img src={require(`./images/slot/${number}.png`)} alt="slot" />
                                        </div>
                                    )
                                })}
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
            <div className={`control-panel ${currentGame.isSpining ? 'spining' : ''}`}>
                <p className="question" onClick={() => {setIsHelpOpen(true); playClickSound()}} onMouseEnter={() => playHoverSound()}>?</p>
                <div 
                    className={`burger sound-btn ${isMenuOpen ? 'open' : ''}`} 
                    onClick={() => {setIsMenuOpen(!isMenuOpen); playClickSound()}}
                    onMouseEnter={() => playHoverSound()}
                >
                    <button></button>
                </div>
                <div className="balance">
                    <h4>БАЛАНС</h4>
                    <div className="control">
                        <strong onClick={() => {setIsFakeBalance(true); playClickSound()}} onMouseEnter={() => playHoverSound()}>&lt;</strong>
                        <div className={`display ${!isFakeBalance ? 'real' : ''}`}>
                            <p className='coin'>{gamer.balance}</p>
                            <p className='curr'>{gamer.currencyBalance} <img src={rubleIcon} alt="ruble icon" /></p>
                        </div>
                        <strong onClick={() => {setIsFakeBalance(false); playClickSound()}} onMouseEnter={() => playHoverSound()}>&gt;</strong>
                    </div>
                </div>
                <div className="spin-controller">
                    <button className='buy' onClick={() => { playClickSound(); setBuyFspeens({ ...buyFspeens, isOpen: true }) }} onMouseEnter={() => playHoverSound()}>КУПИТЬ</button>
                    <div className="spin" onClick={() => setDoSpin(true) }>
                        <img src={require('./images/icons/spin.png')} alt="" />
                    </div>
                    <div 
                        className={`auto-spin ${gameOptions.autoSpin ? 'auto' : ''}`} 
                        onClick={() => {toggleAutoSpin(); playClickSound()}}
                        onMouseEnter={() => playHoverSound()}
                    >
                        <img src={require('./images/icons/auto-spin.png')} alt="" />
                    </div>
                </div>
                <div className="bet">
                    <h4>СУММАРНАЯ СТАВКА</h4>
                    <div className="control">
                        <strong onClick={() => currentGame.bet > 10 ? setCurrentGame({ ...currentGame, bet: currentGame.bet - 10 }) : null}>-</strong>
                        <input 
                            type="text" 
                            inputMode="numeric" 
                            value={currentGame.bet}
                            onChange={e => setCurrentGame({...currentGame, bet: Number(e.target.value)})}
                        />
                        <strong onClick={() => gamer.balance - currentGame.bet > 0 ? setCurrentGame({ ...currentGame, bet: currentGame.bet + 10 }) : null}>+</strong>
                    </div>
                </div>
            </div>
        </div>
        : 
        <div id="load-window" className={isLoadWindowVisible ? 'visible' : 'hidden'}>
            <img className='girl' src={girlOnCloud} alt="Girl on cloud" />
            <img 
                className='start sound-btn' 
                src={startButton} 
                alt="Start button" 
                onClick={slowCloseLoadWindow}
            />
        </div>
    )
}

export default App;